import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { NumberInput } from 'mobx-orm'
import { Button, MenuItem } from '@blueprintjs/core'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'
import { SourceScanner } from 'models/source'
import { ModelFormContext } from 'components/core/inputs/ModelForm/ModelFromContext'


export interface SourceScannerSelectProps {
    input   : NumberInput<any>
    field  ?: string
}

const filterSourceScanner: ItemPredicate<SourceScanner> = (query, scanner, _index, exactMatch) => {
    const normalizedName= scanner.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedName === normalizedQuery;
    } else {
        return `${scanner.id}. ${normalizedName} ${scanner.default_source?.domain}`.indexOf(normalizedQuery) >= 0;
    }
};

const renderSourceScanner: ItemRenderer<SourceScanner> = (scanner, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={scanner.id}
            label={scanner.name}
            onClick={handleClick}
            onFocus={handleFocus}
            roleStructure="listoption"
            text={`${scanner.id}. ${scanner.name}`}
        />
    );
};

// The component is not finished, it is just a placeholder
export const SourceScannerSelect = observer((props: SourceScannerSelectProps) => {
    const { input, field } = props
    const modelForm = useContext(ModelFormContext)

    useEffect(() => {
        // if we have a field then the component are inside a ModelForm
        if (field) {
            modelForm.addField(field, input)
        }
    }, [input, field, modelForm])

    return (
        <Select<SourceScanner>
            items={input.options.items as SourceScanner[]}
            itemPredicate={filterSourceScanner}
            itemRenderer={renderSourceScanner}
            noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
            onItemSelect={(scanner) => input.set(scanner.id)}
        >
            <Button text={
                input.value 
                ? (SourceScanner.get(input.value) as SourceScanner).name 
                : 'Select a scanner'
                }
                rightIcon="double-caret-vertical" placeholder="Select a scanner" />
        </Select>
    )
})