import { Model, model, field } from 'mobx-orm'
import { api } from 'services/http-adapter'

/**
 * Model for lemma Count Trend Chart
 */

// TODO: it should be read-only model
@api('lemma-count-trend')
@model export class LemmaCountTrend extends Model {
    // Note: id is a date string 
    @field count ?: number  
}
