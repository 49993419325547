import './Table.css'
import { NumberInput as NumberModelInput, QueryPage, Query } from 'mobx-orm'
import { observer } from 'mobx-react-lite'



export interface TableProps {
    query        : QueryPage<any> | Query<any>
    columnDefs   : any
    input       ?: NumberModelInput<any>
    onRowClick  ?: (item: any) => void
}


export const Table = observer((props: TableProps) => {
    const { query, columnDefs, input, onRowClick } = props

    if (query.isLoading) {
        return <div>Loading...</div>
    }

    const onRowClickHandler = (item: any) => {
        input.set(item.id)
        onRowClick && onRowClick(item)
    }

    return (
        <table className='table bp5-html-table bp5-compact bp5-interactive bp5-html-table-striped bp5-html-table-bordered'>
            <thead>
                <tr>
                    {columnDefs.map((columnDef: any, index: number) => (
                        <th key={index}>{columnDef.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {query.items.map((item: any, index: number) => (
                    <tr key={index} onClick={() => onRowClickHandler(item)} className={input && input.value === item.id ? 'selected' : ''}>
                        {columnDefs.map((columnDef: any, index: number) => (
                            <td key={index}>{columnDef.data(item)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
})
