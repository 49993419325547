import { Model, model, field, foreign } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { SourcePageLink } from './source-page-link'
import { SourcePageFact } from './source-page-fact'


@api('source-page-link-fact')
@model
export class SourcePageLinkFact extends Model {
    @field link_id      ?: number 
    @field page_fact_id ?: number 

    @foreign(SourcePageLink)    readonly link       : SourcePageLink
    @foreign(SourcePageFact)    readonly page_fact  : SourcePageFact
}
