import { LIKE, EQ, AND, LTE, GTE, QueryPage, ObjectInput, StringInput, NumberInput, DateInput } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { ControlGroup } from '@blueprintjs/core'
import { ObjectSelect } from 'components/core/inputs/ObjectSelect'
import { DateRangeInput } from 'components/core/inputs/DateRangeInput/DateRangeInput'
import { LemmaCountTrendChart } from 'components/source-nlp'
import { LemmaCountTrend } from 'models/source-nlp'
import { Lemma } from 'models/source-nlp/lemma'
import { Page } from 'components/core'
import { useInput, useQuery } from 'utils/mobx'


export const TrendsPage = observer(() => {
    const lemmaInput = useInput(ObjectInput<Lemma>, {
        required: true,
        syncURL : 'lemma',
        model   : Lemma,
        options : Lemma.getQueryPage({
            filter      : LIKE('lemma', new StringInput({ debounce: 600 })),
            limit       : new NumberInput({ value: 20 }),
            autoupdate  : true
        }) as QueryPage<Lemma>,
    })
    const startDateInput = useInput(DateInput, { syncURL: 'start_date', required: true })
    const endDateInput   = useInput(DateInput, { syncURL: 'end_date',   required: true })
    const trendItemsQuery = useQuery(LemmaCountTrend, {
        filter: AND(
            EQ ('lemma_id'  , lemmaInput),
            GTE('start'     , startDateInput),
            LTE('end'       , endDateInput),
        ),
        autoupdate: true,
    })

    console.log('trend render')
    return (
        <Page queries={[lemmaInput.options]}>
            <ControlGroup style={{padding: '10px', border: 'solid 1px'}}>
                <ObjectSelect<Lemma> input={lemmaInput} field='lemma'/>
                <DateRangeInput start={startDateInput} end={endDateInput}/>
            </ControlGroup>
            {/* <div>
                <div> Lemma isReady { lemmaInput.isReady     ? 1:0} ({lemmaInput.__isReady      ? 1:0})</div>
                <div> Start isReady { startDateInput.isReady ? 1:0} ({startDateInput.__isReady  ? 1:0}) </div>
                <div> End   isReady { endDateInput.isReady   ? 1:0} ({endDateInput.__isReady    ? 1:0})</div>
            </div> */}
            <div style={{height: 600}}>
                { !lemmaInput.isReady ? 
                    (trendItemsQuery.isLoading ? <div>Loading...</div> : <div>Set filter...</div>) 
                    : <LemmaCountTrendChart query={trendItemsQuery}/>
                }
            </div>
        </Page>
    )
})
