import { Model, model, field, foreign } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { Source } from './source'


@api('source-scanner')
@model
export class SourceScanner extends Model {
    @field name                 ?: string
    @field instance             ?: string
    @field default_source_id    ?: number 

    @foreign(Source)    readonly default_source: Source 
}
