import { useState } from 'react'
import { DateInput, EQ, LIKE, AND, GTE, LTE, QueryPage, ObjectInput, StringInput, NumberInput } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { ControlGroup } from '@blueprintjs/core'
import { ObjectSelect } from 'components/core/inputs/ObjectSelect'
import { DateRangeInput } from 'components/core/inputs/DateRangeInput/DateRangeInput'
import { SemanticTrend } from 'models/source-nlp'
import { Source } from 'models/source'
import { SemanticTrendChart } from 'components/source-nlp'


export const SemanticPage = observer(() => {
    const [sourceInput] = useState(new ObjectInput<Source>({
        model: Source,
        options: Source.getQueryPage({
            filter: LIKE('domain', new StringInput({ debounce: 600 })),
            limit: new NumberInput({ value: 20 }),
            autoupdate: true
        }) as QueryPage<Source>,
        required: true,
        syncURL: 'source'
    }))
    const [startDateInput]  = useState(new DateInput({ syncURL: 'start_date', required: true })) 
    const [endDateInput]    = useState(new DateInput({ syncURL: 'end_date', required: true }))
    const [semanticTrendQuery] = useState(() => SemanticTrend.getQuery({
        filter: AND(
            EQ('source_id', sourceInput),
            GTE('start', startDateInput),
            LTE('end', endDateInput),
        ),
        autoupdate: true
    }) as QueryPage<SemanticTrend>)

    return (
        <div style={{ padding: '10px' }}>
            <ControlGroup style={{padding: '10px', border: 'solid 1px'}}>
                <ObjectSelect<Source> input={sourceInput} field='domain'/>
                <DateRangeInput start={startDateInput} end={endDateInput}/>
            </ControlGroup>

            <div style={{height: 600}}>
                { !sourceInput.isReady ? 
                    (semanticTrendQuery.isLoading ? <div>Loading...</div> : <div>Set filter...</div>) 
                    : <SemanticTrendChart query={semanticTrendQuery}/>
                }
            </div>
        </div>
    )
})
