import { observer } from 'mobx-react-lite'
import { Source } from 'models/source'


export interface SourceDetailsProps {
    source: Source 
}

export const SourceDetails = observer((props: SourceDetailsProps) => {
    const { source } = props
    if (!source) {
        return <div>loading...</div>
    }
    return (
        <>
            <h2>{source.full_name}</h2>
            <h3> Pages </h3>
            <ul>
                { source.pages.map(page=> {
                    return <li key={page.id}>{page.url}</li>
                    })
                }
            </ul>
        </>
    )
})
