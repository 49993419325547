import './SourceList.css'
import classnames from 'classnames'
import { useState } from 'react'
import { QueryPage, NumberInput as NumberModelInput } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { Source } from 'models/source'
import { SIDEBAR, sidebar } from 'sidebar'


export interface SourceListProps {
    sources: QueryPage<Source> 
}

export const SourceList = observer((props: SourceListProps) => {
    const { sources } = props
    const [sourceInput] = useState(new NumberModelInput({ syncURL: 'source-id' }))
    // TODO: destroy sourceInput
    // useEffect(() => {
    //     return () => { sourceInput.destroy() } 
    // }, [])
    return (
        <div className='source-list'>
            <div> input {sourceInput.value} </div>
        { sources.items.map( source => {
            const listItemClass = classnames({
                'source-list-item': true,
                'selected': sourceInput.value === source.id,
            })
            return <div key={source.id} className={listItemClass} onClick={() =>{
                sourceInput.set(source.id)
                // sidebar.set(SIDEBAR.SOURCE)
            }}>
                {source.full_name}
            </div>
            })
        }
        </div>
    )
})
