import { StringInput, AND, LIKE, QueryPage, ObjectInput } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { StringInputUI } from 'components/core/inputs/StringInput'
import { SourceList } from 'components/source/SourceList'
import { Source, SourcePage } from 'models/source'
import { useInput, useQueryPage } from 'utils/mobx'
import { Page, PageHeader, useBreadCrumbs } from 'components/core'
import { useState } from 'react'
import { SourceListPage } from './source-list'
import { SourceDetailsPage } from './source-details'
import { SourcePageDetailsPage } from './source-page-details'


export const SourcesPage = observer(() => {
    const allSourcesQuery = useQueryPage(Source, { autoupdate: true }) as QueryPage<Source>
    const sourceInput = useInput(ObjectInput<Source>, {syncURL : 'source-id', model: Source })
    const sourcePageInput = useInput(ObjectInput<SourcePage>, {syncURL : 'source-page-id', model: SourcePage })
    const breadcrumbs = useBreadCrumbs([
        { component: <SourceListPage/>       , options: { text: "Sources", icon: "folder-close", }},
        { component: <SourceDetailsPage/>    , options: {                  icon: "folder-close", }, objectInput : sourceInput    , title: (input) => input.obj?.domain },
        { component: <SourcePageDetailsPage/>, options: {                  icon: "folder-close", }, objectInput : sourcePageInput, title: (input) => input.obj?.url },
    ])

    return (
        <Page queries={[allSourcesQuery]}>
            <PageHeader>
                {breadcrumbs.render()}
            </PageHeader>
            {breadcrumbs.current}
        </Page>
    )
})
