import { Model, model, field, foreign, many } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { SourcePage } from './source-page'


@api('source-page-link')
@model
export class SourcePageLink extends Model {
    @field page_from_id     ?: number 
    @field page_to_id       ?: number 

    @foreign(SourcePage)    readonly page_from  : SourcePage
    @foreign(SourcePage)    readonly page_to    : SourcePage
}
many(SourcePageLink, 'page_from_id')(SourcePage, 'links_to')
many(SourcePageLink, 'page_to_id')(SourcePage, 'links_from')
