import './SourcePageList.css'
import classnames from 'classnames'
import { QueryPage, NumberInput } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { SourcePage } from 'models/source'
import { useInput } from 'utils/mobx'


export interface SourcePageListProps {
    sourcePages: QueryPage<SourcePage> 
}

export const SourcePageList = observer((props: SourcePageListProps) => {
    const { sourcePages } = props
    const sourcePageInput = useInput(NumberInput, { syncURL: 'source-page-id' })
    return (
        <div className='source-list'>
        { sourcePages.items.map( sourcePage => {
            const listItemClass = classnames({
                'source-page-list-item': true,
                'selected': sourcePageInput.value === sourcePage.id,
            })
            return <div key={sourcePage.id} className={listItemClass} onClick={() => sourcePageInput.set(sourcePage.id) }>
                {sourcePage.url}
            </div>
            })
        }
        </div>
    )
})
