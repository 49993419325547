import { Model, model, field, foreign, many } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { SourcePage } from './source-page'
import { SourcePageVersion } from './source-page-version'
import { SourceScanSession } from './source-scan-session'


@api('source-page-fact')
@model
export class SourcePageFact extends Model {
    @field timestamp    ?: string
    @field page_id      ?: number 
    @field version_id   ?: number 
    @field session_id   ?: number 

    @foreign(SourcePage)           readonly page    : SourcePage 
    @foreign(SourcePageVersion)    readonly version : SourcePageVersion
    @foreign(SourceScanSession)    readonly session : SourceScanSession
}

many(SourcePageFact, 'page_id')(SourcePage, 'facts')
