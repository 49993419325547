import { observer } from 'mobx-react-lite'
import { PageContent } from 'components/core'
import { useInput, useQueryPage } from 'utils/mobx'
import { AND, ArrayStringInput, EQ, LIKE, NumberInput, ObjectInput, QueryPage, StringInput } from 'mobx-orm'
import { StringInputUI } from 'components/core/inputs'
import { useEffect } from 'react'
import { SourcePage, SourcePageFact, SourcePageLink, SourcePageVersion } from 'models/source'
import { dtf } from 'utils/dates'
import { SourcePageList } from 'components/source'


export const SourceDetailsPage = observer(() => {
    const sourceInput = useInput(ObjectInput, { syncURL: 'source-id'})
    const searchInput = useInput(StringInput, { syncURL: 'page-search', debounce: 600 })
    const sourcePagesQuery = useQueryPage(SourcePage, {
        filter: AND(
            EQ('source_id', sourceInput),
            LIKE('url', searchInput),
        ),
        relations   : new ArrayStringInput({ value: ['facts', 'versions' ]}), 
        autoupdate  : true,
    }) as QueryPage<SourcePage>

    return (
        <PageContent queries={[sourcePagesQuery]}>
            <StringInputUI input={searchInput} />
            <SourcePageList sourcePages={sourcePagesQuery} />
        </PageContent>
    )
})
