import { Model, model, field, foreign, many } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { SourcePageVersion } from 'models/source'
import { Lemma } from './lemma'


@api('lemma-count')
@model
export class LemmaCount extends Model {
    @field version_id   ?: number 
    @field lemma_id     ?: number 
    @field count        ?: number 

    @foreign(SourcePageVersion  ) readonly version    : SourcePageVersion
    @foreign(Lemma              ) readonly lemma      : Lemma 
}
many(LemmaCount, 'lemma_id')(Lemma, 'counts')
