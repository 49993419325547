import { useState } from 'react'
import { Button } from '@blueprintjs/core'
import { observer } from 'mobx-react-lite'
import { NumberInput, Query} from 'mobx-orm'
import { Source, SourceScanner, SourceScanSession } from 'models/source'
import { ModelForm } from 'components/core/inputs'
import { SourceScannerSelect } from 'components/source/SourceScanner/Select'
import { ModelFormApply } from 'components/core/inputs/ModelForm/ModelFormApply'


export interface SourceScannerRunFormProps {
    scanners     : Query<SourceScanner>
    sources     ?: Query<Source>
}


// The component is not finished, it is just a placeholder
export const SourceScannerRunForm = observer((props: SourceScannerRunFormProps) => {
    const { scanners } = props

    const [scannerInput] = useState(() => new NumberInput({
        required: true,
        options: scanners,
        // autoReset: autoResetId,
    }))
    const [newSession, setNewSession] = useState(() => new SourceScanSession())

    return (
        <div style={{display: 'flex', gap: '10px', border: 'solid black 1px', padding: '10px'}}>
            <ModelForm obj={newSession} onApplied={() => setNewSession(new SourceScanSession())}>
                <SourceScannerSelect field='scanner_id' input={scannerInput} />
                <ModelFormApply>
                    <Button intent="success" icon="walk" text="Run" />
                </ModelFormApply>
            </ModelForm>
        </div>
    )
})
