import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { EQ, NumberInput as NumberModelInput } from 'mobx-orm'
import { Source, SourcePage } from 'models/source'
import { SourceDetails } from 'components/source/SourceDetails'
import { SIDEBAR, sidebar } from 'sidebar'


export const SourceSidebar = observer(() => {
    const [sourceInput] = useState(new NumberModelInput({ syncURL: 'source-id' }))
    const [allSourcePages] = useState(() => {
        return SourcePage.getQueryPage({
            filter: EQ('source_id', sourceInput),
            autoupdate: true })
    })

    const source = Source.get(sourceInput.value) as Source
    return (
        <>
            <button onClick={() => {sidebar.set(SIDEBAR.OFF); sourceInput.set(undefined)} }> Close </button>
            <div> Source Details {sourceInput.value}</div>
            { allSourcePages.isLoading && source === undefined
                ? <div>loading...</div>
                : <SourceDetails source={source}/>
            }
        </>
    )
})
