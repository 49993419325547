/* eslint react-hooks/exhaustive-deps: 0 */ 
import { useMemo, useEffect } from 'react'
import { Model, QueryProps, InputConstructorArgs, ObjectInputConstructorArgs } from 'mobx-orm'

/**
 *  Hooks for uging mobx-orm inputs and queries
 */

export const useQuery = <M extends typeof Model>(model: M, options?: QueryProps<InstanceType<M>>) => {
    const query = useMemo(() => model.getQuery(options), [])
    useEffect(() => () => query.destroy(), [])
    return query
}


export const useQueryPage = <M extends typeof Model>(model: M, options?: QueryProps<InstanceType<M>>) => {
    const query = useMemo(() => model.getQueryPage(options), [])
    useEffect(() => () => query.destroy(), [])
    return query
}

export const useQueryRaw = <M extends typeof Model>(model: M, options?: QueryProps<InstanceType<M>>) => {
    const query = useMemo(() => model.getQueryRaw(options), [])
    useEffect(() => () => query.destroy(), [])
    return query
}

export const useQueryRawPage = <M extends typeof Model>(model: M, options?: QueryProps<InstanceType<M>>) => {
    const query = useMemo(() => model.getQueryRawPage(options), [])
    useEffect(() => () => query.destroy(), [])
    return query
}


export const useInput = (InputConstructor: any, options?: InputConstructorArgs<any, any> | ObjectInputConstructorArgs<any, any>) => {
    const input = useMemo(() => new InputConstructor(options), [])
    useEffect(() => () => input.destroy(), [])
    return input
}
