import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import {
  AuthPage, AuthSignInPage, AuthSignUpPage, AuthResetPage,
  BasePage, MainPage, DashboardPage, SourcesPage, TestPage,
  SessionsPage, UsersPage, UserProfilePage, PublicPage, SourcePagesPage,
  SemanticPage, TrendsPage
} from './pages'


const router = createBrowserRouter([
  { path: 'auth', element: <AuthPage/>, children: [
    { path: 'signin', element: <AuthSignInPage/> },
    { path: 'signup', element: <AuthSignUpPage/> },
    { path: 'reset' , element: <AuthResetPage/> },
    { path: ''      , element: <AuthSignUpPage/> },
  ]},
  // available for all
  { path: 'public', element: <PublicPage/> },
  // only for authenticated users
  { path: '', element: <BasePage/>, children: [
    { path: '/'      , element: <MainPage/>, children: [
      { path: 'dashboard'   , element: <DashboardPage/> },
      { path: 'sources'     , element: <SourcesPage/> },
      { path: 'pages'       , element: <SourcePagesPage/> },
      { path: 'sessions'    , element: <SessionsPage/> },
      { path: 'users'       , element: <UsersPage/> },
      { path: 'semantic'    , element: <SemanticPage/> },
      { path: 'trends'      , element: <TrendsPage/> },
      { path: 'user-profile', element: <UserProfilePage/> },
      { path: 'test'        , element: <TestPage/> },
    ]},
    { path: '', element: <MainPage/> },
  ]}
])


function App() {
  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  )
}

export default App
