import { Model, model, field } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { LemmaCount } from './lemma-count'


@api('lemma')
@model
export class Lemma extends Model {
    @field lemma        ?: string
    @field positive     ?: number 
    @field manipulation ?: number 

    readonly counts: LemmaCount[]
}
