import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { reaction, runInAction } from 'mobx'
import { DESC, NumberInput, OrderByInput, Query, QueryPage} from 'mobx-orm'
import { SourceScanner, SourceScanSession } from 'models/source'
import { SourceScanSessionList } from 'components/source/SourceScanSessionList'
import { SIDEBAR, sidebar } from 'sidebar'
import { Page } from 'components/core'
import { SourceScannerRunForm } from 'components/source'
import { useInput, useQuery, useQueryPage } from 'utils/mobx'


export const SessionsPage = observer(() => {
    const sessionIdInput    = useInput(NumberInput, { syncURL: 'session-id' })
    const allScanners       = useQuery(SourceScanner, {autoupdate: true}) as Query<SourceScanner>
    const allSessions       = useQueryPage(SourceScanSession, {
        limit   : new NumberInput({ value: 15}), 
        orderBy : new OrderByInput({value: new Map([['updated_at', DESC]]) }),
        autoupdate: true,
    }) as QueryPage<SourceScanSession> 

    useEffect(() => {
        return reaction(
            () => SourceScanSession.repository.cache.store.size,
            () => allSessions.shadowLoad()
        )
    }, [])

    const onRowClickHandler = (session: SourceScanSession) => {
        sidebar.set(SIDEBAR.SESSION)
    }

    return (
        <Page queries={[allScanners, allSessions]}>
            <div> Sessions </div>
            <div>
                <SourceScannerRunForm scanners={allScanners} />
            </div>
            <div style={{ overflow: 'auto' }}>
                <SourceScanSessionList sessions={allSessions} input={sessionIdInput} onRowClick={onRowClickHandler}/>
            </div>
        </Page>
    )
})
