
import { Model, model, field, foreign, many } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { SourcePage } from './source-page'


@api('source-page-version')
@model
export class SourcePageVersion extends Model {
    @field page_id  ?: number 
    @field body     ?: string
    @field hash     ?: string

    @foreign(SourcePage) readonly page: SourcePage 
}
many(SourcePageVersion, 'page_id')(SourcePage, 'versions')
