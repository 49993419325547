import './PageHeader.css'

export interface PageHeaderProps {
    children     : React.ReactNode
}

export const PageHeader = (props: PageHeaderProps) => {
    return (
        <div className="page-header">
            {props.children}
        </div>
    )
}
