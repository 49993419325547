import { observer } from 'mobx-react-lite'
import { Query } from 'mobx-orm'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { SemanticTrend } from 'models/source-nlp'


export interface SemanticTrendChartProps {
    query: Query<SemanticTrend>
}

export const SemanticTrendChart = observer((props: SemanticTrendChartProps) => {
    const { query } = props
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={300} data={query.items} margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="id" />
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Line type="monotone" dataKey="positive" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="manipulation" stroke="#82ca9d" />
            </LineChart>
        </ResponsiveContainer>
    )
})
