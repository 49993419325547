import './PageContent.css'
import { reaction } from 'mobx'
import { Query } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Spinner } from '@blueprintjs/core'


export interface PageContentProps {
    queries     ?: Query<any>[]
    children     : React.ReactNode
}

export const PageContent = observer((props: PageContentProps) => {
    const { queries, children } = props
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (!queries) {
            setIsReady(true)
            return
        }
        return reaction(
            () => queries.every(query => !query.isLoading && query.isReady),
            (isReady) => isReady && setIsReady(isReady)
        )
    }, [queries])

    return (
        <div className='page-content' >
            { !isReady ?  <Spinner style={{margin: 'auto'}} size={200}/> : children}
        </div>
    )
})
