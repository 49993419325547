import { Table } from 'components/core/Table'
import { NumberInput, QueryPage, Query } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { SourceScanSession } from 'models/source'
import { dtf } from 'utils/dates'


export interface SourceScanSessionListProps {
    sessions     : QueryPage<SourceScanSession> | Query<SourceScanSession>
    input       ?: NumberInput<any>
    onRowClick  ?: (item: SourceScanSession) => void
}


const columnDefs = [
    {'data': (item: SourceScanSession) => item.id,                  'title': 'ID'},
    {'data': (item: SourceScanSession) => item.scanner?.name,       'title': 'Scanner'},
    {'data': (item: SourceScanSession) => item.source?.full_name,   'title': 'Source'},
    {'data': (item: SourceScanSession) => dtf(item.updated_at),     'title': 'Updated At'},
    {'data': (item: SourceScanSession) => item.scan_task_id,        'title': 'Scan Task ID'},
    {'data': (item: SourceScanSession) => item.status_scan_task,    'title': 'Scan Task Status'},
    {'data': (item: SourceScanSession) => item.load_db_task_id,     'title': 'Load DB Task ID'},
    {'data': (item: SourceScanSession) => item.status_load_db_task, 'title': 'Load DB Task Status'},
]

export const SourceScanSessionList = observer((props: SourceScanSessionListProps) => {
    const { sessions, input, onRowClick} = props
    return (
        <Table query={sessions} columnDefs={columnDefs} onRowClick={onRowClick} input={input}/>
    )
})
