import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { EQ, NumberInput } from 'mobx-orm'
import { SourcePageFact, SourceScanSession } from 'models/source'
import { SIDEBAR, sidebar } from 'sidebar'
import { dtf } from 'utils/dates'


export const SourceScanSessionSidebar = observer(() => {
    const [sessionInput] = useState(new NumberInput({ syncURL: 'session-id' }))
    const [scanFacts] = useState(() => SourcePageFact.getQuery({
        filter: EQ('session_id', sessionInput),
        autoupdate: true,
        // relations: ['page', ],
    }))

    useEffect(() => {
        // destructor
        return () => {
            // sessionInput.destroy()
            // scanFacts.destroy()
        }
    },[])

    const session = SourceScanSession.get(sessionInput.value) as SourceScanSession 
    if (session === undefined || scanFacts === undefined) {
        return <div>loading...</div>
    }

    return (
        <>
            <div> Session </div>
            <button onClick={() => {sidebar.set(SIDEBAR.OFF); sessionInput.set(undefined)} }> Close </button>
            <div> { session.id }</div>
            <div style={{overflowY: 'auto', height: '100%'}}>
                { !scanFacts.isReady ? 'Loading...' :
                    <ul>
                        {scanFacts.items.map((fact: any, index: number) => (
                            <li key={index}>
                                {fact.id} {dtf(fact.timestamp)} {fact.page.url }
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </>
    )
})
