import React, { useEffect, useMemo } from 'react'
import { makeAutoObservable, observable, reaction } from 'mobx'
import { Breadcrumbs, BreadcrumbProps } from '@blueprintjs/core'
import { Model, ObjectInput } from 'mobx-orm'


interface BreadCrumbOption<M extends Model> {
    objectInput ?: ObjectInput<M>
    title       ?: (objectInput: ObjectInput<M>) => string,
    component   ?: React.ReactNode
    options     ?: BreadcrumbProps,
}


export class BreadCrumbs {
    @observable protected stack = []
                protected options = []
                protected disposer

    constructor(options: BreadCrumbOption<any>[]) {
        this.options = options 
        makeAutoObservable(this)
        this.disposer = reaction( 
            () => this.options.map(option => option.objectInput?.value), 
            () => {
                this.stack = []
                for (let i = 0; i < this.options.length; i++) {
                    const option = this.options[i]
                    let current = false
                    if (option.objectInput === undefined || option.objectInput.value !== undefined) {
                        const text = option.objectInput ? option.title(option?.objectInput) : option.options.text
                        let onClick
                        if (i === this.options.length - 1) {
                            current = true  // the last one is always current
                            onClick = () => {}     // the last one is always do nothing 
                        } else {
                            const position = i
                            // if we click on the breadcrumb, we should clear all the inputs after it
                            onClick = () => {
                                for (let j = position+1; j < this.options.length; j++) {
                                    const oldOption = this.options[j]
                                    if (oldOption.objectInput) {
                                        oldOption.objectInput.set(undefined)
                                    }
                                }
                            }
                        }
                        const props = { ...option.options, text, current, onClick, component: option.component }
                        this.stack.push(props)
                    }
                }
            },
            { fireImmediately: true }
        ) 
    }

    destroy() {
        this.disposer() 
    }

    get current(): React.ReactNode {
        return this.stack[this.stack.length - 1]?.component
    }

    render(): React.ReactNode {
        return (<Breadcrumbs items={this.stack}/>)
    }
}

export const useBreadCrumbs = (options: BreadCrumbOption<any>[]) => {
    const breadcrumbs = useMemo(() => new BreadCrumbs(options), [])
    useEffect(() => () => breadcrumbs.destroy(), [])
    return breadcrumbs 
}