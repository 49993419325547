import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'


export const UsersPage = observer(() => {

    console.log('UsersPage iter')
    useEffect(() => {
        console.log('UsersPage init')
        return () => {
            console.log('UsersPage cleanup')
        }  
    }, [])
    return (
        <div> Users </div>
    )
})
