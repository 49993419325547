import { Source } from 'models/source'
import { sidebar } from 'sidebar'
import { observer } from 'mobx-react-lite'
import { StringInput, AND, LIKE, QueryPage } from 'mobx-orm'
import { useInput, useQueryPage } from 'utils/mobx'
import { Page } from 'components/core'


export const TestPage = observer(() => {
    const searchInput = useInput(StringInput, { syncURL: 'search', debounce: 600 })
    const allSources  = useQueryPage(Source, {
        filter: AND(
            LIKE('domain', searchInput),
        ),
        // relations: ['pages'],
        autoupdate: true 
    }) as QueryPage<Source>

    return (
        <Page queries={[allSources]}>
            <button onClick={() => sidebar.set(sidebar.value ? undefined : 'user')}> toggle left sidebar </button>
            {/* <Input input={searchInput} /> */}
            { allSources.items.map(source=> {
                return <li key={source.id}>
                    {source.domain} {source?.pages?.length}
                    <ul>
                        { source.pages.map(page=> {
                            return <li key={page.id}>{page.url}</li>
                            })
                        }
                    </ul>
                </li>
                })
            }
        </Page>
    )
})
