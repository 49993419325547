import { Model, model, field, foreign } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { Source } from './source'
import { SourceScanner } from './source-scanner'
import pub_sub from 'services/pub-sub'
import { AsyncTask } from 'models/core/async-task'


@api('source-scan-session')
@model
export class SourceScanSession extends Model {
    @field source_id            ?: number 
    @field scanner_id           ?: number 
    @field updated_at           ?: string
    @field scan_task_id         ?: string
    @field scan_task_status     ?: string
    @field load_db_task_id      ?: string
    @field load_db_task_status  ?: string

    @foreign(Source)        readonly source         : Source 
    @foreign(SourceScanner) readonly scanner        : SourceScanner 

    get status_scan_task() : string {
        let async_task: AsyncTask = AsyncTask.get(this.scan_task_id as any)
        return async_task ?  async_task.status : this.scan_task_status
    }
    get status_load_db_task() : string {
        let async_task: AsyncTask = AsyncTask.get(this.load_db_task_id as any)
        return async_task ?  async_task.status : this.load_db_task_status
    }
}

pub_sub.register_model('source_scan_session', SourceScanSession)
