import me from 'services/me' 
import { StringInputUI } from 'components/core/inputs/StringInput' 
import { StringInput } from "mobx-orm"
import { useMemo } from "react"

export const AuthSignInPage = () => {
    const username_input = useMemo(() => new StringInput(), [])
    const password_input = useMemo(() => new StringInput(), [])

    const signInHandler = async () => {
        console.log("Sign In")
        await me.login(username_input.value, password_input.value)
    }

    return (
        <>
            <h1>Auth Sign In page</h1>
            <div>
                <StringInputUI input={username_input} />
                <StringInputUI input={password_input} />
            </div>
            <button onClick={signInHandler}> Sign In </button>
        </>
    )
}
