import { useState } from 'react'
import { StringInput, NumberInput, ArrayStringInput, AND, LIKE } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { StringInputUI } from 'components/core/inputs/StringInput'
import { SourcePage, SourcePageFact, SourcePageVersion } from 'models/source'
import { dtf } from 'utils/dates'
import { useInput, useQueryPage } from 'utils/mobx'
import { Page } from 'components/core'


export const SourcePagesPage = observer(() => {
    const searchInput = useInput(StringInput, { syncURL: 'search', debounce: 600 })
    const allPages = useQueryPage(SourcePage, {
        filter: AND(
            LIKE('url', searchInput),
        ),
        limit       : new NumberInput({ value: 50 }), 
        relations   : new ArrayStringInput({ value: ['facts', 'versions' ]}), 
        autoupdate  : true,
    })

    return (
        <Page queries={[allPages]}>
            <StringInputUI input={searchInput} />
            <ul style={{overflowY: 'auto'}}>
                { allPages.isLoading 
                    ? 'Loading...'
                    : allPages.items.map((page: SourcePage, index: number) => (
                        <li key={index}>
                            {page.id} {page.url}
                            { page.versions.length && <div> Versions </div> }
                            { page.versions.length && 
                                <ul>
                                    {page.versions.map((version: SourcePageVersion, index: number) => (
                                        <li key={index}>
                                            {version.id} {version.hash}
                                        </li>
                                    ))}
                                </ul>
                            }
                            { page.facts.length && <div> Facts </div> }
                            { page.facts.length && 
                                <ul>
                                    {page.facts.map((fact: SourcePageFact, index: number) => (
                                        <li key={index}>
                                            {fact.id} {dtf(fact.timestamp)}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </li>
                    ))
                }
            </ul>
        </Page>
    )
})
