import { Model, model, field } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { SourcePage } from './source-page'


@api('source')
@model
export class Source extends Model {
    @field schema?: string
    @field domain?: string

    readonly pages: SourcePage[]

    get full_name() : string {
        return `${this.schema}://${this.domain}`
    }
}
