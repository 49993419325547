import { observer } from 'mobx-react-lite'
import { PageContent } from 'components/core'
import { useInput, useQuery } from 'utils/mobx'
import { EQ, ObjectInput, Query, StringInput } from 'mobx-orm'
import { StringInputUI } from 'components/core/inputs'
import { Navbar, Tab, TabId, TabPanel, Tabs, TabsExpander } from '@blueprintjs/core'
import { SourcePage, SourcePageFact, SourcePageVersion } from 'models/source'


const TABS = {
    CONTEXT : 'tab-page-context',
    VERSIONS: 'tab-page-versions',
    FACTS   : 'tab-page-facts',
}

export const SourcePageDetailsPage = observer(() => {
    const pageInput = useInput(ObjectInput, { model: SourcePage     , syncURL: 'source-page-id' })
    const tabInput =  useInput(StringInput, { value: TABS.CONTEXT   , syncURL: 'tab' })

    const allSourcePageVersions = useQuery(SourcePageVersion, {
        filter: EQ('page_id', pageInput),
        autoupdate: true 
    }) as Query<SourcePageVersion>

    const allSourcePageFacts = useQuery(SourcePageFact, {
        filter: EQ('page_id', pageInput),
        autoupdate: true 
    }) as Query<SourcePageFact>

    return (
        <PageContent queries={[allSourcePageFacts, allSourcePageVersions]}>
            <Tabs large={true}  selectedTabId={tabInput.value} onChange={(navbarTabId: TabId) => tabInput.set(navbarTabId) }>
                <Tab id={TABS.CONTEXT}  title="Context"  panel={<SourcePageContextTab  page={pageInput.obj}/> } />
                <Tab id={TABS.VERSIONS} title="Versions" panel={<SourcePageVersionsTab page={pageInput.obj}/> } />
                <Tab id={TABS.FACTS}    title="Facts"    panel={<SourcePageFactsTab    page={pageInput.obj}/> } />
                <TabsExpander />
            </Tabs>
        </PageContent>
    )
})


interface SourcePageTabProps{
    page: SourcePage 
}

const SourcePageContextTab = observer((props: SourcePageTabProps) => {
    const { page } = props
    return (
        <div>
            <h2>Context</h2>
            <div> { page?.url } </div>
        </div>
    )
})

const SourcePageVersionsTab = observer((props: SourcePageTabProps) => {
    const { page } = props
    return (
        <div>
            <h2>Versions</h2>
            <ul>
                { page?.versions.map(version => <li key={version.id}> {version.hash} </li>) }
            </ul>
        </div>
    )
})

const SourcePageFactsTab = observer((props: SourcePageTabProps) => {
    const { page } = props
    return (
        <div>
            <h2>Facts</h2>
            <ul>
                { page?.facts.map(fact => <li key={fact.id}> {fact.timestamp} </li>) }
            </ul>
        </div>
    )
})
