// TODO: MOBX-ORM: rename inputs to model-inputs
import { StringInput } from 'mobx-orm'

export interface StringInputProps {
    input: StringInput
}

export const StringInputUI = (props: StringInputProps) => {
    const { input } = props
    return (
        <input type='text' value={input.value} onChange={(e) => { input.set(e.target.value) }} />
    )
}
