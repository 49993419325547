import { Model, model, field } from 'mobx-orm'
import { api } from 'services/http-adapter'

/**
 * Model for Semantic Trend Chart
 */


// TODO: it should be read-only model
@api('semantic-trend')
@model export class SemanticTrend extends Model {
    // Note: id is a date string 
    @field positive     ?: number  
    @field manipulation ?: number
}
