import { StringInput, AND, LIKE, QueryPage } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { StringInputUI } from 'components/core/inputs/StringInput'
import { SourceList } from 'components/source/SourceList'
import { Source } from 'models/source'
import { useInput, useQueryPage } from 'utils/mobx'
import { PageContent } from 'components/core'


export const SourceListPage = observer(() => {
    const searchInput = useInput(StringInput, { syncURL: 'search', debounce: 600 })
    const allSources = useQueryPage(Source, {
        filter: AND(
            LIKE('domain', searchInput),
        ),
        autoupdate: true 
    }) as QueryPage<Source>

    return (
        <PageContent queries={[allSources]}>
            <StringInputUI input={searchInput} />
            <SourceList sources={allSources} />
        </PageContent>
    )
})
