import { observer } from 'mobx-react-lite'
import { User } from 'models/user'
import { me } from 'services'
import { Button } from '@blueprintjs/core'


export const UserProfilePage = observer(() => {
    const user = User.get(me.user_id) as User | undefined
    return (
        <div>
            <div>{user?.id} | {user?.first_name}</div>
            <Button intent="danger" icon="log-out" text="Logout" onClick={() => me.logout() } />
        </div>
    )
})
