import { Outlet } from 'react-router'

export const AuthPage = () => {
    return (
        <>
            <h1>Auth page</h1>
            <Outlet />
        </>
    )
}
