import { Model, model, field, foreign, many } from 'mobx-orm'
import { api } from 'services/http-adapter'
import { Source } from './source'
import { SourcePageVersion } from './source-page-version'
import { SourcePageLink } from './source-page-link'
import { SourcePageFact } from './source-page-fact'


@api('source-page')
@model
export class SourcePage extends Model {
    @field source_id    ?: number 
    @field url          ?: string
    @field first_fact_id?: number 
    @field last_fact_id ?: number 

    @foreign(Source)         readonly source    : Source 
    // @foreign(SourcePageFact) readonly first_fact: SourcePageFact
    // @foreign(SourcePageFact) readonly last_fact : SourcePageFact 

    readonly facts      : SourcePageFact[]
    readonly versions   : SourcePageVersion[]
    readonly links_to   : SourcePageLink[]
    readonly links_from : SourcePageLink[]
}
many(SourcePage, 'source_id')(Source, 'pages')
